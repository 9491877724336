<style lang="scss" scoped>
$offset: 10px;
.row {
  background-color: #f3f3f4;
  margin-top: $offset + 10px;

  .category {
    transform: translateY(-$offset);
    text-align: center;

    .icon {
      display: inline-block;
      position: relative;

      .letter {
        border: 1px solid red;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: relative;
        font-weight: bold;
        font-size: 24px;
        transition: 0.2s all;
        span {
          transition: 0.2s all;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        background-color: #fff;
      }

      .point {
        width: 50px;
        height: 50px;

        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: -10px;
        top: -10px;
      }
    }
    //   $c-umsetzung: #6ea0d1;
    // $c-sonstige: #3c97a0;;
    &.neuentwicklung {
      .point {
        background-color: $c-neuentwicklung;
      }
      .letter {
        border-color: $c-neuentwicklung;
        span {
          color: $c-neuentwicklung;
        }
      }
    }
    &.umsetzung {
      .point {
        background-color: $c-umsetzung;
      }
      .letter {
        border-color: $c-umsetzung;
        span {
          color: $c-umsetzung;
        }
      }
    }
    &.sonstige {
      .point {
        background-color: $c-sonstige;
      }
      .letter {
        border-color: $c-sonstige;
        span {
          color: $c-sonstige;
        }
      }
    }
  }

  .hover:hover {
    cursor: pointer;

    &.neuentwicklung {
      .letter {
        background-color: $c-neuentwicklung;
        span {
          color: #fff;
        }
      }
    }
    &.umsetzung {
      .letter {
        background-color: $c-umsetzung;
        span {
          color: #fff;
        }
      }
    }
    &.sonstige {
      .letter {
        background-color: $c-sonstige;
        span {
          color: #fff;
        }
      }
    }
  }

  .name {
    font-weight: bold;
    font-size: 22px;
  }
}
</style>

<template>
  <div class="categoryheader row justify-content-center">
    <div class="category neuentwicklung" :class="[{ hover: onOpen }, wide ? 'col-4' : 'col-2']" @click="onOpen('Neuentwicklung')">
      <div class="icon">
        <div class="point"></div>
        <div class="letter">
          <span>N</span>
        </div>
      </div>

      <div class="name" style="color:#cb5f63">Neuentwicklung</div>
    </div>
    <div class="category umsetzung" :class="[{ hover: onOpen }, wide ? 'col-4' : 'col-2']" @click="onOpen('Umsetzung')">
      <div class="icon">
        <div class="point"></div>
        <div class="letter">
          <span>U</span>
        </div>
      </div>

      <div class="name" style="color:#71a0cd">Umsetzung</div>
    </div>
    <div class="category sonstige" :class="[{ hover: onOpen }, wide ? 'col-4' : 'col-2']" @click="onOpen('Sonstige')">
      <div class="icon">
        <div class="point"></div>
        <div class="letter">
          <span>S</span>
        </div>
      </div>

      <div class="name" style="color:#3c97a0">Sonstige Skills</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    wide: { type: Boolean, default: false },
  },
  setup() {
    let onOpen = inject('onOpen') as Function;

    return {
      onOpen,
    };
  },
});
</script>
