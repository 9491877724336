
import { defineComponent, computed } from 'vue';
import CategoryHeader from './CategoryHeader.vue';

export default defineComponent({
  props: {
    skills: {
      type: Array,
      required: true,
    },
    personal: {
      type: Boolean,
      default: false,
    },
    absprachen: {
      type: String
    }
  },
  components: {
    CategoryHeader,
  },
  setup(props) {
    const getDims = (skill: any) => {
      if (skill === undefined) {
        return [
          { name: 'Loading', value: 0 },
          { name: 'Loading', value: 0 },
        ];
      }
      return [
        {
          // @ts-ignore
          name: skill.skill.dim1,
          // @ts-ignore
          value: skill.grades.dim1,
        },
        {
          // @ts-ignore
          name: skill.skill.dim2,
          // @ts-ignore
          value: skill.grades.dim2,
        },
      ];
    };
    
    const absprachenparsed = computed(()=> props.absprachen?.replace(/\n/g, '<br/>'));
    return {
      getDims,
      absprachenparsed
    };
  },
});
