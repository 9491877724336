<style lang="scss">
.profile {
  h3 {
    margin-bottom: 40px;
    letter-spacing: 1px;
    text-align: center;
    
  }

  .absprache-box {
      width:42%;
      margin:0 auto;
      padding-bottom:80px;
    h3 {
      background:#f6f6f6;
      border:1px solid #f6f6f6;
      padding:10px;
      font-size:18px;
      font-family: 'HelveticaNeueLTPro-Roman';
      margin-bottom:0;
    }
    .txt-box {
      font-family: 'HelveticaNeueLTPro-Roman';
      border:1px solid #000;
      border-top:0px;
      line-height:1.8;
      padding:15px 25px;
      text-align:center; 
    }
   
  }

  .personal-h3 {

  }
  

  .topskillcontainer  {
    margin-top: 80px;
    margin-bottom: 80px !important;
  }

  // .col-2.middle {
  //   border-left: 1px solid red;
  //   border-right: 1px solid red;
  // }

  .categoryheader {
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .legendcontainer {
    position: relative;
    max-width: 250px;
    .legend {
      position: absolute;
      top: 0;
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 11px;

      .dimension {
        display: flex;
        align-items: center;
        .point {
          height: 40px;
          width: 40px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
  }

  .topskillcontainer {
    h3 {
      margin-bottom: 50px !important;
    }
    .topskill {
      text-align: center;

      .dimcontainer {
        margin: 0 auto;
      }

      .topskillcaption {
        .name {
          font-weight: bold;
          letter-spacing: 1px;
        }
        margin-bottom: 5px;
      }
    }
  }

  .skill {
    //display: flex;
    //align-items: center;
    margin-top: 10px;
    padding: 20px 30px;

    .dimcontainer {
      width: 72px;
    }

    .dimcontainer .dim,
    .dimcontainer .point {
      height: 35px !important;
      width: 35px !important;
      font-size: 14px;
    }

    .name {
      font-size: 120%;

      margin-bottom: 10px;
      font-family: 'HelveticaNeueLTPro-Md';
    }
    .balken-outer {
      margin-bottom: 10px;
      .balken-name {
        width: 20%;
        float: left;

        span {
          display: block;
        }
      }
      .balken-box {
        width: 80%;
        float: left;

        padding: 7px 0;
        .balken-bg {
          width: 100%;
          height: 6px;
          background: #d9dada;
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          .balken-inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;

            border-radius: 5px;
          }
        }
      }
    }
    .skalla-outer {
      border-top: 1px solid #000;
      width: 80%;
      margin-left: 20%;
      position: relative;
      .skalla-inner {
        position: absolute;
        top: 0px;

        font-size: 12px;

        span {
          display: inline-block;
          padding-top: 6px;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 1px;
            height: 6px;
            position: absolute;
            background: #000;
            top: 0;
          }
        }
      }
      .skallav0 {
        left: 0;
        span {
          left: -3px;
          &:after {
            left: 3px;
          }
        }
      }
      .skallav1 {
        left: 33.33%;
        span {
          &:after {
            left: 50%;
          }
        }
      }
      .skallav2 {
        left: 66.66%;
        span {
          &:after {
            left: 50%;
          }
        }
      }
      .skallav3 {
        right: 0%;
        span {
          right: -3px;
          &:after {
            right: 3px;
          }
        }
      }
    }
  }
  .kat-neuentwicklung {
    border-right: 1px solid #b1b2b3;
    .name {
      color: $c-neuentwicklung;
    }
    .balken-name {
      span {
        color: $c-neuentwicklung;
      }
    }
    .balken-inner {
      background: $c-neuentwicklung;
    }
  }
  .kat-umsetzung {
    border-right: 1px solid #b1b2b3;
    .name {
      color: $c-umsetzung;
    }
    .balken-name {
      span {
        color: $c-umsetzung;
      }
    }
    .balken-inner {
      background: $c-umsetzung;
    }
  }
  .kat-sonstige-skills {
    .name {
      color: $c-sonstige;
    }
    .balken-name {
      span {
        color: $c-sonstige;
      }
    }
    .balken-inner {
      background: $c-sonstige;
    }
  }
  
}
  .description {
   margin-top:0px !important;
 
  }
</style>

<template>
  <div class="profile">
    <div class="row justify-content-center topskillcontainer" v-if="skills.length > 0">
      <div class="col-12">
        <h3>{{ personal ? 'Deine 3 Top-Skills in 2 Dimensionen:' : '3 Top-Skills in 2 Dimensionen:' }}</h3>
      </div>
      <div class="col-12 ">
        <div class="row justify-content-center ">
          <div class="col-2 topskill">
            <div class="topskillcaption">
              <span class="percent">{{ skills[0].percentall }}%</span> <span class="name">{{ skills[0].skill.name }}</span>
            </div>
          </div>
          <div class="col-2 topskill">
            <div class="topskillcaption">
              <span class="percent">{{ skills[1].percentall }}%</span> <span class="name">{{ skills[1].skill.name }}</span>
            </div>
          </div>
          <div class="col-2 topskill">
            <div class="topskillcaption">
              <span class="percent">{{ skills[2].percentall }}%</span> <span class="name">{{ skills[2].skill.name }}</span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center ">
          <div class="col-2 topskill">
            <Points :dims="getDims(skills[0])" overlap />
          </div>
          <div class="col-2 topskill">
            <Points :dims="getDims(skills[1])" overlap />
          </div>
          <div class="col-2 topskill">
            <Points :dims="getDims(skills[2])" overlap />
          </div>
        </div>
      </div>
    </div>
    <div class="absprache-box" v-if="absprachen && absprachen!=''">
      <h3>Info zu Skills</h3>
      <div class="txt-box" v-html="absprachenparsed"></div>
    </div>

    <h3 class="personal-h3">{{ personal ? 'Deine weiteren Skills in Kategorien:' : 'Weitere Skills in Kategorien:' }}</h3>

    <CategoryHeader wide />

    <!-- <div class="legendcontainer">
      <div class="legend">
        <div class="head">
          Legende abgefragter Dimensionen:
        </div>
        <div class="dimension">
          <div class="point point-kreativitaet"></div>
          <div class="name">KREATIVITÄT</div>
        </div>
        <div class="dimension">
          <div class="point point-konzeption"></div>
          <div class="name">KONZEPTION</div>
        </div>
        <div class="dimension">
          <div class="point point-tools"></div>
          <div class="name">TOOLS</div>
        </div>
        <div class="dimension">
          <div class="point point-technische"></div>
          <div class="name">TECHNISCHE SKILLS</div>
        </div>
        <div class="dimension">
          <div class="point point-methodik"></div>
          <div class="name">METHODIK</div>
        </div>
        <div class="dimension">
          <div class="point point-erfahrung"></div>
          <div class="name">ERFAHRUNG/INTERESSE</div>
        </div>
        <div class="dimension">
          <div class="point point-spezifisch"></div>
          <div class="name">SPEZIFISCHE KENNTNISSE</div>
        </div>
      </div>
    </div> -->

    <div class="row skillscols justify-content-center">
      <div class="col-4 kat-neuentwicklung">
        <div class="skill" v-for="skill in skills.filter((skill) => skill.skill.group == 'Neuentwicklung')">
          <!-- <div><Points nolabels :dims="getDims(skill)" /></div> -->
          <div class="name">
            {{ skill.percentall }}%
            {{ skill.skill.name }}
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim1 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent1}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim2 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent2}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="skalla-outer">
            <div class="skalla-inner skallav0"><span>0</span></div>
            <div class="skalla-inner skallav1"><span>1</span></div>
            <div class="skalla-inner skallav2"><span>2</span></div>
            <div class="skalla-inner skallav3"><span>3</span></div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="col-4 middle kat-umsetzung">
        <div class="skill" v-for="skill in skills.filter((skill) => skill.skill.group == 'Umsetzung')">
          <!-- <div><Points nolabels :dims="getDims(skill)" /></div> -->
          <div class="name">
            {{ skill.percentall }}%
            {{ skill.skill.name }}
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim1 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent1}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim2 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent2}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="skalla-outer">
            <div class="skalla-inner skallav0"><span>0</span></div>
            <div class="skalla-inner skallav1"><span>1</span></div>
            <div class="skalla-inner skallav2"><span>2</span></div>
            <div class="skalla-inner skallav3"><span>3</span></div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="col-4 kat-sonstige-skills">
        <div class="skill" v-for="skill in skills.filter((skill) => skill.skill.group == 'Sonstige')">
          <!-- <div><Points nolabels :dims="getDims(skill)" /></div> -->

          <div class="name">
            {{ skill.percentall }}%
            {{ skill.skill.name }}
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim1 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent1}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="balken-outer">
            <div class="balken-name">
              <span>{{ skill.skill.dim2 }}</span>
            </div>
            <div class="balken-box">
              <div class="balken-bg">
                <div class="balken-inner" :style="`width: ${skill.percent2}%`"></div>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="skalla-outer">
            <div class="skalla-inner skallav0"><span>0</span></div>
            <div class="skalla-inner skallav1"><span>1</span></div>
            <div class="skalla-inner skallav2"><span>2</span></div>
            <div class="skalla-inner skallav3"><span>3</span></div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import CategoryHeader from './CategoryHeader.vue';

export default defineComponent({
  props: {
    skills: {
      type: Array,
      required: true,
    },
    personal: {
      type: Boolean,
      default: false,
    },
    absprachen: {
      type: String
    }
  },
  components: {
    CategoryHeader,
  },
  setup(props) {
    const getDims = (skill: any) => {
      if (skill === undefined) {
        return [
          { name: 'Loading', value: 0 },
          { name: 'Loading', value: 0 },
        ];
      }
      return [
        {
          // @ts-ignore
          name: skill.skill.dim1,
          // @ts-ignore
          value: skill.grades.dim1,
        },
        {
          // @ts-ignore
          name: skill.skill.dim2,
          // @ts-ignore
          value: skill.grades.dim2,
        },
      ];
    };
    
    const absprachenparsed = computed(()=> props.absprachen?.replace(/\n/g, '<br/>'));
    return {
      getDims,
      absprachenparsed
    };
  },
});
</script>
