
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Profile from '../../components/Profile.vue';

export default defineComponent({
  components: {
    Profile,
  },
  setup() {
    const store = useStore();
    const skills = ref([]);


    const absprachen = computed(() => store.getters.getUserAbsprachen);


    store.dispatch('fetchSkillsForCurrentUser').then((res) => {
      skills.value = res
        .map((skill: any) => {
          skill.combined = skill.grades.dim1 + skill.grades.dim2;
          skill.percent1 = (skill.grades.dim1 / 3) * 100;
          skill.percent2 = (skill.grades.dim2 / 3) * 100;
          skill.percentall = Math.ceil((skill.combined / 6) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.combined > b.combined) return -1;
          if (a.combined < b.combined) return 1;

          return 0;
        });
    });

    return {
      skills,
      absprachen
    };
  },
});
