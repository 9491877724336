
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    wide: { type: Boolean, default: false },
  },
  setup() {
    let onOpen = inject('onOpen') as Function;

    return {
      onOpen,
    };
  },
});
